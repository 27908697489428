import React, { useState } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateOpen: (admin: any) =>
      dispatch({ type: "UPDATE_ADMINOPEN", payload: admin }),
    update: (admin: any) =>
      dispatch({ type: "UPDATE_SELECTEDADMIN", payload: admin }),
  };
};
const mapStateToProps = (state: any) => {
  return { state: state };
};

const AdminCard = (props: any) => {
  const state = props;
  const setOpen = state.updateOpen;
  const admin = props.admin;
  const update = state.update;

  const { t } = useTranslation();

  const editAdmin = () => {
    setOpen(admin);
    update(admin);
  };
  return (
    <div className="card">
      <div className="p-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src="https://www.w3schools.com/howto/img_avatar.png"
              alt="Avatar"
              className="rounded-circle mx-1"
              style={{ width: "60px", height: "60px" }}
            />
            <div className="ms-3">
              <div className="fw-bold">{admin.UserName}</div>

              <div className="text-muted">{t(admin.AdminRole)}</div>
            </div>
          </div>
          {((state.state.role === 2 &&
            (admin.IdAdminRole === 3 || admin.IdAdminRole === 4)) ||
            (state.state.role === 1 && admin.IdAdminRole !== 1)) && (
            <div>
              <i
                className="bi bi-pencil-square mx-2 text-primary cursor-pointer"
                onClick={() => editAdmin()}></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminCard);
