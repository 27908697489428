import React, { useEffect, useState } from "react";
import PostCard from "../post-card/Post-card";
import Spinner from "../spinner/Spinner";
import { useTranslation } from "react-i18next";
import { fetchApi } from "../../utils/Connections";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePosts: (posts: any) =>
      dispatch({ type: "UPDATE_POSTS", payload: posts }),
    resetPosts: () => dispatch({ type: "RESET_POSTS" }),
  };
};

const MultiPost = (props: any) => {
  let offset: number = 0;

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const [posts, setPosts] = useState<any>([]);

  const { state } = props;
  const updatePosts = props.updatePosts;

  const { switchDeleted } = props || false;

  useEffect(() => {
    setLoading(true);
    if (props.userPosts === undefined) {
      if (state.posts.length === 0) _GetAllPosts();
      else {
        setPosts(state.posts);
        setLoading(false);
      }
    } else {
      setPosts(props.userPosts);
      setLoading(false);
    }
  }, [props.userPosts]);

  useEffect(() => {
    if (props.userPosts === undefined) setPosts(state.posts);
    else setPosts(props.userPosts);
  }, [state.posts, props.userPosts]);

  const _GetAllPosts = async () => {
    setLoading(true);
    const result = await fetchApi(
      `/Admins/GetAllPosts?Offset=${offset}&Deleted=${deleted}`,
      "GET",
      {}
    );
    if (result.StatusCode === 200) {
      if (result.My_Result.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let p = [];
      if (offset === 0) {
        p = result.My_Result;
        
      } else {
        p = [...state.posts, ...result.My_Result];
      }
      updatePosts(p);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const loadMore = () => {
    setLoading(true);
    if (props.screen === "user") {
      props.loadMore();
    } else {
      offset += 1;
      _GetAllPosts();
    }
  };

  useEffect(() => {
    if (!props.hasMore) {
      setHasMore(false);
    }
  }, [props.hasMore]);

  useEffect(() => {
    setPosts([]);
    _GetAllPosts();
  }, [deleted]);

  const { t } = useTranslation();
  return (
    <div className="fluid-container mx-3">
      {switchDeleted && (
        <div className="d-flex justify-content-end">
          <button
            className="btn asm-btn-bg-color-primary text-white"
            onClick={() => {
              offset = 0;
              setDeleted(!deleted);
            }}>
            {t(deleted ? "All Posts" : "DeletedPosts")}
          </button>
        </div>
      )}
      <div className="row">
        {posts.map((post: any, index: number) => (
          <div className="col-xl-4 col-lg-6 col-12 mt-3" key={post.IdPost}>
            <PostCard deleted={deleted} post={post} index={index}></PostCard>
          </div>
        ))}
      </div>
      <div>
        {loading && <Spinner />}
        {posts.length === 0 && !loading && (
          <div className="text-center text-muted mt-3">{t("NoPosts")}</div>
        )}
        {!loading && hasMore && posts.length !== 0 && (
          <button
            className="btn asm-btn-bg-color-primary w-100 text-white mt-3"
            onClick={() => loadMore()}>
            {t("LoadMore")}
          </button>
        )}
        {!hasMore && posts.length !== 0 && (
          <div className="text-center text-muted mt-3">{t("NoMorePost")}s</div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiPost);
