import React, { useEffect, useState, useRef } from "react";
import AddModerators from "../../components/addModerators/AddModerators";
import AdminCard from "../../components/admin-card/AdminCard";
import { connect } from "react-redux";

import { fetchApi } from "../../utils/Connections";
import Spinner from "../../components/spinner/Spinner";

import { useTranslation } from "react-i18next";

const admin = {
  id: -1,
  name: "",
  role: 0,
  img: "",
  email: "",
};
const mapStateToProps = (state: any) => state;

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateOpen: () => dispatch({ type: "UPDATE_ADMINOPEN", payload: admin }),
    updateAdmins: (admins: any) =>
      dispatch({ type: "UPDATE_ADMINS", payload: admins }),
    resetAdmins: () => dispatch({ type: "RESET_ADMINS" }),
    updateSelectedAdmin: (admin: any) =>
      dispatch({ type: "UPDATE_SELECTEDADMIN", payload: admin }),
  };
};

const UsersAndPermissions = (props: any) => {
  const state = props;
  const setOpen = state.updateOpen;

  const [admins, setAdmins] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const [hasMore, setHasMore] = useState(true);

  const { t } = useTranslation();

  let offset = useRef<any>(0);

  useEffect(() => {
    offset.current = 0;
    if (props.Admins.length === 0) getAdmins();
    else {
      setAdmins(props.Admins);
      setLoading(false);
    }
  }, [state.Admins]);

  const getAdmins = async () => {
    setLoading(true);
    const result = await fetchApi(
      `Admins/GetAdmins?Offset=${offset.current}`,
      "GET",
      {}
    );
    if (result.StatusCode === 200) {
      if (result.My_Result.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let a = [];
      if (state.Admins.length === 0) {
        a = result.My_Result;
      } else {
        a = [...admins, ...result.My_Result];
      }
      props.updateAdmins(a);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const loadMore = () => {
    setLoading(true);
    offset.current += 1;
    getAdmins();
  };

  useEffect(() => {
    setAdmins(state.Admins);
  }, [state.Admins]);

  return (
    <>
      <div className="py-4">
        <div className="mt-5 d-flex flex-column align-items-center">
          <h4 className="text-center">{t("AdminsTitle")}</h4>
          <div className="d-flex justify-content-center">
            <button
              className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
              onClick={() => {
                setOpen();
                props.updateSelectedAdmin(undefined);
              }}>
              {t("AddAdmin")}
            </button>
          </div>
        </div>
        <div className="fluid-container mx-3">
          <div className="row">
            {admins.map((admin: any) => (
              <div
                key={admin.IdAdmin}
                className="col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                <AdminCard admin={admin}></AdminCard>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            {loading && <Spinner />}
            {admins.length === 0 && !loading && (
              <div className="text-center text-muted mt-3">{t("NoAdmins")}</div>
            )}
            {hasMore && !loading && admins.length !== 0 && (
              <button
                className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
                onClick={loadMore}>
                {t("LoadMore")}
              </button>
            )}
            {!hasMore && admins.length !== 0 && (
              <div className="text-center text-muted mt-3">
                {t("NoMoreAdmins")}
              </div>
            )}
          </div>
        </div>
      </div>
      <AddModerators></AddModerators>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersAndPermissions);
