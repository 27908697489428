import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./screens/dashboard/Dashboard";
import Login from "./screens/login/Login";
import "./App.css";
import AllPosts from "./screens/allPosts/AllPosts";
import AllUsers from "./screens/allUsers/AllUsers";
import PostScreen from "./screens/postScreen/PostScreen";
import UserScreen from "./screens/userScreen/UserScreen";
import ReportScreen from "./screens/reportScreen/ReportScreen";
import Settings from "./screens/settings/Settings";
import Ticket from "./screens/ticket/Ticket";
import Analytics from "./screens/analytics/Analytics";
import UsersAndPermissions from "./screens/UsersAndPermissions/UsersAndPermissions";
import { connect } from "react-redux";
import { requestForToken } from "./push-notification";
import AllForms from "./screens/allForms/AllForms";
import Form from "./screens/form/Form";

// Call the function wherever you need the device ID

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateNotificationToken: (token: any) => {
      dispatch({ type: "UPDATE_NOTIFICATION_TOKEN", payload: token });
    },
  };
};

const App = (props: any) => {
  const { state } = props;
  const [LoggedIn, updateLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      updateLoggedIn(true);
    }
  }, [state]);

  useEffect(() => {
    const askForNotificationPermission = async () => {
      try {
        const token = await requestForToken();
        if (token !== undefined) props.updateNotificationToken(token);
      } catch (error) {
        // console.error(error);
      }
    };
    askForNotificationPermission();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/Dashboard"
        element={LoggedIn ? <Dashboard /> : <Navigate to="/" replace />}>
        <Route
          path="/Dashboard"
          element={<Navigate to="/Dashboard/AllPosts" replace />}
        />
        <Route path="/Dashboard/AllPosts" element={<AllPosts />} />
        <Route path="/Dashboard/AllUsers" element={<AllUsers />} />
        <Route path="/Dashboard/Post" element={<PostScreen />} />
        <Route path="/Dashboard/User" element={<UserScreen />} />
        {state.role !== 4 && (
          <Route path="/Dashboard/AllReports" element={<ReportScreen />} />
        )}
        <Route path="/Dashboard/Settings" element={<Settings />} />
        {(state.role === 1 || state.role === 2) && (
          <Route
            path="/Dashboard/Settings/UsersAndPermissions"
            element={<UsersAndPermissions />}
          />
        )}
        {state.role !== 3 && (
          <Route path="/Dashboard/Ticket" element={<Ticket />} />
        )}
        {(state.role === 1 || state.role === 2) && (
          <Route path="/Dashboard/Analytics" element={<Analytics />} />
        )}
        <Route path="/Dashboard/AllForms" element={<AllForms />} />
        <Route path="/Dashboard/Form" element={<Form />} />
        {/* <Route
          path="*"
          element={<Navigate to="/Dashboard/AllPosts" replace />}
        /> */}
      </Route>
      <Route path="*" element={<Login />}></Route>
    </Routes>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
