const initialState = {
  username: sessionStorage.getItem("username") || "",
  token: sessionStorage.getItem("token") || null,
  role: JSON.parse(sessionStorage.getItem("role") || "0"),
  notificationToken: null,

  navigation: {
    nav: [
      {
        id: 0,
        name: "Analytics",
        icon: "bi bi-bar-chart",
        link: "./Analytics",
        roles: [1],
      },
      {
        id: 1,
        name: "All Posts",
        icon: "bi bi-file-post",
        link: "./AllPosts",
        roles: [1, 2, 3, 4],
      },
      {
        id: 2,
        name: "All Users",
        icon: "bi bi-person",
        link: "./AllUsers",
        roles: [1, 2, 3, 4],
      },
      {
        id: 3,
        name: "Reports",
        icon: "bi bi-exclamation-triangle",
        link: "./AllReports",
        roles: [1, 2, 3],
      },
      {
        id: 4,
        name: "Ticket",
        icon: "bi bi-chat",
        link: "./Ticket",
        roles: [1, 2, 4],
      },
      {
        id: 5,
        name: "All Forms",
        icon: "bi bi-file-earmark-text",
        link: "./AllForms",
        roles: [1, 2, 3, 4],
      },
    ],
    showSidebar: false,
  },

  selectedPost: {},
  selectedUser: {},
  selectedAdmin: undefined,

  posts: [],
  Users: [],
  Reports: [],
  Tickets: [],
  Admins: [],

  banPeriods: [
    { name: "1 Day", duration: 1 },
    { name: "2 Days", duration: 2 },
    { name: "1 Week", duration: 7 },
    { name: "1 Month", duration: 30 },
    { name: "Permanent", duration: null },
  ],

  modal: {
    showModal: false,
    modalType: null,
  },
  addAdminOpen: false,
  adminToEdit: {
    id: 0,
    name: "",
    email: "",
    role: "",
  },
};

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LoggedIn":
      return {
        ...state,
        username: action.username,
        token: action.token,
        role: action.role,
      };

    case "UPDATE_NOTIFICATION_TOKEN":
      return {
        ...state,
        notificationToken: action.payload,
      };

    case "UPDATE_SHOWSIDEBAR":
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showSidebar: action.payload,
        },
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        modal: {
          ...state.modal,
          showModal: !state.modal.showModal,
          modalType: action.payload,
        },
      };
    case "UPDATE_ADMINOPEN":
      return {
        ...state,
        addAdminOpen: !state.addAdminOpen,
        adminToEdit: action.payload,
      };

    case "UPDATE_POSTS":
      return {
        ...state,
        posts: action.payload,
      };

    case "RESET_POSTS":
      return {
        ...state,
        posts: [],
      };

    case "UPDATE_USERS":
      return {
        ...state,
        Users: action.payload,
      };
    case "RESET_USERS":
      return {
        ...state,
        Users: [],
      };

    case "UPDATE_SELECTEDPOST":
      return {
        ...state,
        selectedPost: action.payload,
      };
    case "UPDATE_SELECTEDUSER":
      return {
        ...state,
        selectedUser: action.payload,
      };
    case "UPDATE_REPORTS":
      return {
        ...state,
        Reports: action.payload,
      };
    case "RESET_REPORTS":
      return {
        ...state,
        Reports: [],
      };
    case "UPDATE_TICKETS":
      return {
        ...state,
        Tickets: action.payload,
      };
    case "RESET_TICKETS":
      return {
        ...state,
        Tickets: [],
      };
    case "UPDATE_ADMINS":
      return {
        ...state,
        Admins: action.payload,
      };
    case "RESET_ADMINS":
      return {
        ...state,
        Admins: [],
      };
    case "UPDATE_SELECTEDADMIN":
      return {
        ...state,
        selectedAdmin: action.payload,
      };

    default:
      break;
  }

  return state;
};
export default rootReducer;
