import React, { useState } from "react";
import { useNavigate } from "react-router";

import Modal from "../modal/Modal";

import { connect } from "react-redux";
import moment from "moment";

import { fetchApi } from "../../utils/Connections";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const mapStateToProps = (state: any) => {
  return { state: state };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPost: (post: any) => dispatch({ type: "SET_POST", payload: post }),
    updateUsers: (users: any) =>
      dispatch({ type: "UPDATE_USERS", payload: users }),
  };
};

const UserCard = (props: any) => {
  const currentLanguageCode = cookies.get("i18next") || "en";

  const showBan: Boolean = props.showBan;
  const [showDropdown, setShowDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let [_banLiftTime, setBanLiftTime] = useState("");

  const { t } = useTranslation();
  const state = props.state;
  const user = props._user;

  const handleChange = (date: any) => {
    const timestamp = Date.now();
    if (date.target.valueAsNumber < timestamp) {
      alert("Please select a future date");
      return;
    }
    setBanLiftTime(moment(date.target.valueAsNumber).toISOString());
    setShowDropdown(false);
    setOpenModal(true);
  };

  const setBanPeriod = (period: any) => {
    const banLiftTime = moment().add(period, "days").valueOf();
    setBanLiftTime(moment(banLiftTime).toISOString());
    setShowDropdown(false);
    setOpenModal(true);
  };

  const banUser = async () => {
    const id = user.IdUser;

    const body = {
      IdUser: id,
      BanRemovalDate: _banLiftTime,
    };
    const result = await fetchApi(`ADMINS/BanUser`, "DELETE", body);
    if (result.StatusCode === 200) {
      let index = state.Users.findIndex((u: any) => u.IdUser === id);
      let users = [...state.Users];
      users.splice(index, 1);
      props.updateUsers(users);
    } else {
      alert("Something went wrong");
    }
    setOpenModal(false);
  };

  const unBan = async () => {
    const id = user.IdUser;

    const body = {
      IdUser: id,
    };
    const result = await fetchApi(`Admins/UnBanUser`, "PUT", body);
    if (result.StatusCode === 200) {
      let index = state.Users.findIndex((u: any) => u.IdUser === id);
      let users = [...state.Users];
      users.splice(index, 1);
      props.updateUsers(users);
    } else {
      alert("Something went wrong");
    }
    setOpenModal(false);
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="card border-0 shadow mb-3">
        <div className="p-2 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={user.ProfilePicture}
              alt=""
              className="rounded-circle m-2 userImage cursor-pointer"
              onClick={() =>
                navigate(`/Dashboard/User?id=${user.IdUser}`)
              }></img>
            <div>
              <div
                className="fw-bold cursor-pointer"
                onClick={() => navigate(`/Dashboard/User?id=${user.IdUser}`)}>
                {user.UserName}
              </div>
              <div className="text-size-sm text-muted">
                <i className="bi bi-envelope mx-1"></i>
                <span>
                  <a
                    href={`mailto:${user.Email}`}
                    className="text-decoration-none">
                    {user.Email}
                  </a>
                </span>
              </div>
              <div>
                <span>
                  {t("Posts")}: {user.PostCount}
                </span>
              </div>
            </div>
          </div>
          {state.role !== 4 && !showBan && (
            <div className="d-flex align-items-center justify-content-center">
              {state.role !== 4 && !user.Banned && (
                <div className={`dropdown ${showDropdown ? "show" : ""}`}>
                  <button
                    className="btn asm-btn-bg-color-primary text-white dropdown-toggle"
                    type="button"
                    onClick={() => setShowDropdown(!showDropdown)}>
                    <span className="ms-1">{t("Ban")}</span>
                  </button>
                  <div
                    className={`dropdown-menu ${showDropdown ? "show" : ""}`}
                    style={{
                      transform:
                        currentLanguageCode === "en"
                          ? "translateX(-60%)"
                          : "translateX(60%)",
                    }}>
                    {state.banPeriods.map((period: any) => {
                      return (
                        <div
                          key={period.duration}
                          className="dropdown-item"
                          onClick={() => {
                            setBanPeriod(period.duration);
                          }}>
                          {t(period.name)}
                        </div>
                      );
                    })}

                    <div className="dropdown-item">
                      <input
                        type="date"
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {user.Banned && (
                <button
                  className="btn asm-btn-bg-color-primary text-white"
                  type="button"
                  onClick={() => setOpenModal(true)}>
                  {t("Unban")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        title={t("BUModal")}
        open={openModal}
        CloseModal={() => setOpenModal(false)}>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-danger mx-2"
            onClick={() => (user.Banned ? unBan() : banUser())}>
            {user.Banned ? t("Unban") : t("Ban")}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setOpenModal(false)}>
            {t("Cancel")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
