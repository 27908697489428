import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import GeneralSettings from "../../components/generalSettings/GeneralSettings";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCollapse: (index: number) =>
      dispatch({ type: "UPDATE_SETTINGS_COLLAPSE", payload: index }),
  };
};

const mapStateToProps = (state: any) => state;

const Settings = (props: any) => {
  const state = props;
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="py-5">
      <div className="fluid-container m-3">
        {/* <div className="card ">
          <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold">Dark Mode</div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={`card  ${
            state.role === 1 || state.role === 2
              ? "rounded-0 rounded-top border-bottom-0"
              : ""
          }`}>
          <div className="p-3 cursor-pointer" onClick={() => setShow(!show)}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold">{t("GeneralSettings")}</div>
              <div className="px-2">
                <i className="bi bi-chevron-down"></i>
              </div>
            </div>
          </div>
          <Collapse in={show}>
            <div>
              <GeneralSettings></GeneralSettings>
            </div>
          </Collapse>
        </div>
        {(state.role === 1 || state.role === 2) && (
          <div className="card rounded-0 rounded-bottom ">
            <div
              className="d-flex justify-content-between align-items-center p-3 cursor-pointer"
              onClick={() => {
                navigate("UsersAndPermissions");
              }}>
              <div className="fw-bold">{t("UsersAndPermissions")}</div>
              <div className="px-2">
                <i className="bi bi-box-arrow-up-right"></i>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
