import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Post from "../../components/post/Post";
import PostComment from "../../components/postComment/PostComment";
import Spinner from "../../components/spinner/Spinner";
import { useTranslation } from "react-i18next";

import { fetchApi } from "../../utils/Connections";

import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedPost: (value: any) =>
      dispatch({ type: "UPDATE_SELECTEDPOST", payload: value }),
  };
};
const PostScreen = (props: any) => {
  let offset = 0;
  const state = props.state;
  const [comments, setComments] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const [loading, setLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    _GetPost();
  }, []);

  const _GetPost = async () => {
    setLoading(true);
    let result;
    if (type === "Report") {
      result = await fetchApi(
        `Admins/GetReportPost?IdReport=${id}&Offset=${offset}`,
        "GET",
        {}
      );
      if (result.StatusCode === 200) {
        props.setSelectedPost(result.My_Result);
        if (result.My_Result.Comments.length === 0) {
          setHasMore(false);
          setLoading(false);
          setLoadingScreen(false);
          return;
        }

        let c = [];
        if (offset === 0) {
          c = result.My_Result.Comments;
        } else {
          c = [...comments, ...result.My_Result.Comments];
        }
        setComments(c);
      } else {
        alert("Something went wrong");
      }
    } else {
      result = await fetchApi(
        `/Admins/GetPostDetails?Offset=${offset}&Type=Comments&IdPost=${id}`,
        "GET",
        {}
      );
      if (result.StatusCode === 200) {
        let post = sessionStorage.getItem("selectedPost");
        if (post !== null) {
          props.setSelectedPost(JSON.parse(post));
        }
        if (result.My_Result.length === 0) {
          setHasMore(false);
          setLoading(false);
          setLoadingScreen(false);
          return;
        }
        let c = [];
        if (offset === 0) {
          c = result.My_Result;
        } else {
          c = [...comments, ...result.My_Result];
        }

        setComments(c);
      } else {
        alert("Something went wrong");
      }
    }
    setLoading(false);
    setLoadingScreen(false);
  };

  const loadMore = () => {
    setLoading(true);
    offset++;
    _GetPost();
  };

  const deleteComment = async (idComment: string) => {
    let index = comments.findIndex((c: any) => c.IdComment === idComment);
    let c: any = [...comments];
    c.splice(index, 1);
    setComments(c);
  };

  return (
    <>
      <div className="py-5">
        <div className="mx-3 m-2">
          {loadingScreen && (
            <div className="pt-3">
              <Spinner />
            </div>
          )}
          {!loadingScreen && state.selectedPost && (
            <div className="row">
              <div className="col-lg-6 col-12 border-end mt-2">
                <Post post={state.selectedPost}></Post>
              </div>
              {comments.length !== 0 ? (
                <div className="col-lg-6 col-12 mt-2">
                  {comments.map((comment: any) => (
                    <PostComment
                      key={comment.IdComment}
                      comment={comment}
                      deleteComment={() =>
                        deleteComment(comment.IdComment)
                      }></PostComment>
                  ))}
                  {loading && <Spinner />}
                  {!loading && comments.length === 0 && (
                    <div className="text-center text-muted mt-3">
                      {t("NoComments")}
                    </div>
                  )}
                  {hasMore && !loading && comments.length !== 0 && (
                    <button
                      className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
                      onClick={loadMore}>
                      {t("LoadMore")}
                    </button>
                  )}
                  {!hasMore && comments.length !== 0 && (
                    <div className="text-center text-muted mt-3">
                      {t("NoMoreComments")}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostScreen);
