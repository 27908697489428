import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchApi } from "../../utils/Connections";
import { useNavigate, useRoutes } from "react-router";
import Spinner from "../../components/spinner/Spinner";

const AllForms = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getForms();
  }, []);

  const getForms = async () => {
    setLoading(true);
    const response = await fetchApi("Admins/GetForms", "GET", {});
    if (response.StatusCode === 200) {
      setForms(response.My_Result);
    } else {
      alert("Error in fetching forms");
    }
    setLoading(false);
  };

  return (
    <div className="py-4">
      <div className="text-center d-flex justify-content-center align-items-center mt-5 ">
        <h3 className=" asm-color-primary ">{t("All Forms")}</h3>
      </div>
      <div className="fluid-container mx-3">
        <div className="row">
          {forms.map((form: any, index: number) => (
            <div key={index} className="col-lg-4 col-md-6 col-12">
              <div
                className="card mt-3 shadow border-0 p-3"
                onClick={() => {
                  navigate(`/Dashboard/Form?id=${form.IdForm}`);
                }}>
                {form.Form}
              </div>
            </div>
          ))}
        </div>
        {loading && <Spinner />}
      </div>
    </div>
  );
};

export default AllForms;
