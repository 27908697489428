import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import Spinner from "../../components/spinner/Spinner";

import { fetchApi } from "../../utils/Connections";

const mapDispatchToProps = (dispatch: any) => {
  return {
    LoggedIn: (username: string, token: string, role: number) =>
      dispatch({
        type: "LoggedIn",
        username: username,
        token: token,
        role: role,
      }),
  };
};

const mapStateToProps = (state: any) => {
  return { state: state };
};

const Login = (props: any) => {
  const { LoggedIn } = props;
  const [username, updateUsername] = useState<string>("");
  const [password, updatePassword] = useState<string>("");
  const [showPassword, updateShowPassword] = useState<boolean>(false);
  const [loading, updateLoading] = useState<boolean>(false);

  const currentLanguageCode = cookies.get("i18next") || "en";

  const { t } = useTranslation();

  const navigate = useNavigate();

  useRef();

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      navigate("/Dashboard");
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        _Login();
      }
    };
    if (!loading) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [username, password]);

  const _Login = async () => {
    updateLoading(true);
    const result = await fetchApi("AUTH/AdminLogin", "POST", {
      UserName: username,
      Password: password,
      DeviceId: props.state.notificationToken,
    });
    if (result.StatusCode === 200) {
      LoggedIn(
        username,
        result.My_Result.JWT_Token,
        result.My_Result.IdAdminRole
      );
      sessionStorage.setItem("token", result.My_Result.JWT_Token);
      sessionStorage.setItem(
        "role",
        JSON.stringify(result.My_Result.IdAdminRole)
      );
      sessionStorage.setItem("username", username);
      sessionStorage.setItem("id", result.My_Result.IdAdmin);
      navigate("/Dashboard");
    } else {
      alert("Wrong username or password");
    }
    updateLoading(false);
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center vh-100 "
      id="login">
      <div className="card border-0 shadow login-width">
        <div className="text-center pt-3 m-0 h2 asm-color-primary fw-bold">
          DESOCIAL{" "}
        </div>
        <div className="card-body">
          <label htmlFor="username" className="ms-1">
            {t("Username")}
          </label>
          <input
            type="text"
            name="username"
            placeholder={t("Username")}
            className="form-control"
            onChange={(e) => updateUsername(e.target.value)}
            value={username}
          />
          <label htmlFor="password" className="ms-1 mt-2">
            {t("Password")}
          </label>
          <fieldset className="form-group position-relative ">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control mb-1 pe-4"
              placeholder={t("Password")}
              name="password"
              onChange={(e) => updatePassword(e.target.value)}
              value={password}
            />
            <div
              className="form-control-position"
              onClick={() => {
                updateShowPassword(!showPassword);
              }}>
              <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}></i>
            </div>
          </fieldset>
          <div className="d-grid gap-2 mt-3">
            <div className="d-flex justify-content-center">
              {loading ? <Spinner /> : null}
            </div>
            <button
              className="btn asm-bg-color-primary text-white"
              onClick={_Login}
              disabled={loading ? true : false}>
              {t("Login")}
            </button>
          </div>
          <div>
            <button
              className="btn btn-link text-decoration-none w-100"
              onClick={() => {
                i18next.changeLanguage(
                  currentLanguageCode === "en" ? "ar" : "en"
                );
              }}>
              {t("loginChangeLanguage")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
