import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { fetchApi } from "../../utils/Connections";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => state;
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateLoggedIn: (username: string, token: string, role: number) =>
      dispatch({
        type: "UPDATE_LOGGEDIN",
        username: username,
        token: token,
        role: role,
      }),
  };
};

const GeneralSettings = (props: any) => {
  const [username, updateUsername] = useState<string>("");
  const [password, updatePassword] = useState<string>("");
  const [newPassword, updateNewPassword] = useState<string>("");
  const [confirmNewPassword, updateConfirmNewPassword] = useState<string>("");

  const { t } = useTranslation();

  const state = props;

  const updateLoggedIn = state.updateLoggedIn;

  useEffect(() => {
    updateUsername(state.username);
  }, []);

  const ChangePassword = async () => {
    if (password === "" || newPassword === "" || confirmNewPassword === "") {
      alert("Please fill all fields");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      alert("Passwords don't match");
      return;
    }
    let id = sessionStorage.getItem("id");
    if (id !== null) {
      const result = await fetchApi("Admins/UpdateAdmin", "PUT", {
        UserName: state.username,
        Password: newPassword,
        OldPassword: password,
        IdAdminRole: state.role,
        IdAdmin: JSON.parse(id),
        IdUpdatingAdmin: JSON.parse(id),
      });
      if (result.StatusCode === 200) {
        alert("Password changed successfully");
      } else {
        alert("Something went wrong");
        return;
      }
    }
  };

  const Save = async () => {
    if (username === "") {
      alert("Please fill username");
      return;
    }
    if (username === state.username) {
      alert("Username is same as old username");
      return;
    }
    let id = sessionStorage.getItem("id");
    if (id !== null) {
      const result = await fetchApi("Admins/UpdateAdmin", "PUT", {
        UserName: username,
        Password: null,
        OldPassword: null,
        IdAdminRole: state.role,
        IdAdmin: JSON.parse(id),
        IdUpdatingAdmin: JSON.parse(id),
      });
      if (result.StatusCode === 200) {
        alert("Username changed successfully");
        updateLoggedIn(username, state.token, state.role);
        sessionStorage.setItem("username", username);
      } else {
        alert("Something went wrong");
        return;
      }
    }
  };

  return (
    <div className="p-3">
      <div className="mb-3">
        <label className="p-1 mt-1">{t("Username")}</label>
        <input
          type="text"
          className="form-control"
          placeholder="Username"
          value={username}
          onChange={(e) => updateUsername(e.target.value)}
        />
      </div>
      <button
        className="btn asm-btn-bg-color-primary text-white w-100 my-2"
        onClick={() => Save()}>
        {t("Save")}
      </button>

      <div className="my-3">
        <div>
          <label className="p-1 mt-1">{t("CurrentPassword")}</label>
          <input
            type="text"
            className="form-control"
            placeholder={t("CurrentPassword")}
            value={password}
            onChange={(e) => updatePassword(e.target.value)}
          />
        </div>
        <div>
          <label className="p-1 mt-1">{t("NewPassword")}</label>
          <input
            type="text"
            className="form-control"
            placeholder={t("NewPassword")}
            value={newPassword}
            onChange={(e) => updateNewPassword(e.target.value)}
          />
        </div>
        <div>
          <label className="p-1 mt-1">{t("ConfirmPassword")}</label>
          <input
            type="text"
            className="form-control"
            placeholder={t("ConfirmPassword")}
            value={confirmNewPassword}
            onChange={(e) => updateConfirmNewPassword(e.target.value)}
          />
        </div>
      </div>
      <button
        className="btn asm-btn-bg-color-primary text-white w-100 my-2"
        onClick={ChangePassword}>
        {t("ChangePassword")}
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
