import React, { useEffect, useState } from "react";
import { fetchApi } from "../../utils/Connections";
import Spinner from "../../components/spinner/Spinner";
import { useTranslation } from "react-i18next";

const Form = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const { t } = useTranslation();

  // get query params
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");

  const getFormUsers = async () => {
    setLoading(true);
    const response = await fetchApi(
      `Admins/GetFormFills?IdForm=${id}&Offset=${offset}`,
      "GET",
      {}
    );
    if (response.StatusCode === 200) {
      if (response.My_Result.length < 20) {
        setLoading(false);
        setHasMore(false);
        setUsers([...users, ...response.My_Result]);
        return;
      }

      setUsers([...users, ...response.My_Result]);
    } else {
      alert("Error fetching form users");
    }
    setLoading(false);
  };

  useEffect(() => {
    getFormUsers();
  }, [offset]);

  const loadMore = () => {
    setOffset(offset + 1);
  };
  return (
    <div className="py-4">
      <div className="fluid-container mx-3 mt-5 ">
        <div className="row">
          {users?.map((user: any, index: number) => (
            <div key={index} className="col-lg-4 col-md-6 col-12">
              <div className="card mt-3 shadow border-0 p-3">
                <h5 className="text-capitalize">{user.FullName}</h5>
                <a href={`mailto${user.Email}`}>
                  <i className="bi bi-envelope pe-1"></i>
                  {user.Email}
                </a>
                <a href={`tel:${user.Phone}`}>
                  <i className="bi bi-telephone pe-1"></i>
                  {user.Phone}
                </a>
                <p>{user.Comment}</p>
              </div>
            </div>
          ))}
        </div>

        {loading && <Spinner />}
        {users?.length === 0 && !loading && (
          <div className="text-center text-muted mt-3">{t("NoUsers")}</div>
        )}
        {hasMore && !loading && users.length !== 0 && (
          <button
            className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
            onClick={loadMore}>
            {t("LoadMore")}
          </button>
        )}
        {!hasMore && users.length !== 0 && (
          <div className="text-center text-muted mt-3">{t("NoMoreUsers")}</div>
        )}
      </div>
    </div>
  );
};

export default Form;
