import React, { useEffect, useState, useRef } from "react";
import Report from "../../components/report/Report";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import { fetchApi } from "../../utils/Connections";
import Spinner from "../../components/spinner/Spinner";

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateReports: (reports: any) =>
      dispatch({ type: "UPDATE_REPORTS", payload: reports }),
    resetReports: () => dispatch({ type: "RESET_REPORTS" }),
  };
};

const mapStateToProps = (state: any) => {
  return { state: state };
};

const ReportScreen = (props: any) => {
  const state = props.state;
  let offset = useRef<any>(0);
  const [reports, setReports] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [reportTypes, setReportTypes] = useState<any>([
    {
      idReportType: 1,
      ReportType: "Inappropriate",
    },
    {
      idReportType: 2,
      ReportType: "Spam",
    },
    {
      idReportType: 3,
      ReportType: "Offensive",
    },
    {
      idReportType: 4,
      ReportType: "Other",
    },
  ]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [solved, setSolved] = useState(false);

  useEffect(() => {
    setHasMore(true);
    setLoading(true);
    // getReportTypes();
    if (state.Reports.length === 0) getReports();
    else {
      setReports(state.Reports);
      setLoading(false);
    }
  }, []);

  const getReports = async () => {
    const results = await fetchApi(
      `/ADMINS/GetReports?Offset=${offset.current}&Solved=${solved}`,
      "GET",
      {}
    );
    if (results.StatusCode === 200) {
      if (results.My_Result.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let r;
      if (reports.length === 0) {
        r = results.My_Result;
      } else {
        r = [...state.Reports, ...results.My_Result];
      }
      setReports(r);
      props.updateReports(r);
    }
    setLoading(false);
  };

  const filteredReports = () => {
    if (selectedFilter === "All") {
      return reports;
    }
    if (selectedFilter === "Other") {
      return reports.filter(
        (report: any) =>
          report.Reason !== "Inappropriate" &&
          report.Reason !== "Spam" &&
          report.Reason !== "Offensive"
      );
    }
    return reports.filter((report: any) => report.Reason === selectedFilter);
  };

  const loadMore = () => {
    setLoading(true);
    offset.current = offset.current + 1;
    getReports();
  };

  useEffect(() => {
    offset.current = 0;
    setReports([]);
    props.resetReports();
    setLoading(true);
    setHasMore(true);

    getReports();
  }, [solved]);

  const solveReport = (idReport: number) => {
    let r = props.state.Reports;
    let index = r.findIndex((x: any) => x.IdReport === idReport);
    r.splice(index, 1);
    props.updateReports(r);
  };

  // const getReportTypes = async () => {
  //   const result = await fetchApi(`Admins/GetReportTypes`, "GET", {});
  //   if (result.StatusCode === 200) {
  //     setReportTypes(result.My_Result);
  //   } else {
  //     alert("Something went wrong");
  //   }
  // };

  const { t } = useTranslation();
  return (
    <div className="py-4">
      <div className="text-center d-flex justify-content-center align-items-center mt-5">
        <h3 className=" asm-color-primary">{t("Reports")}</h3>
      </div>

      <div className="d-flex justify-content-end align-items-center mx-3">
        <label className="mx-3 d-flex">
          <i className="bi bi-funnel"></i> {t("Filter")}
        </label>
        <select
          className="form-select"
          aria-label="Default select example"
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}>
          <option selected value={"All"}>
            {t("Reports")}
          </option>
          {reportTypes.map((reportType: any) => (
            <option key={reportType.idReportType} value={reportType.ReportType}>
              {t(reportType.ReportType)}
            </option>
          ))}
        </select>
      </div>
      <div className="fluid-container mt-4 mx-3">
        <div className="d-flex justify-content-end ">
          <button
            className="btn asm-btn-bg-color-primary text-white mb-3"
            onClick={() => {
              setReports([]);
              props.resetReports();
              setSolved(!solved);
            }}>
            {t(solved ? "UnsolvedReports" : "SolvedReports")}
          </button>
        </div>
        <div className="row">
          {filteredReports().map((report: any, index: number) => (
            <div key={report.IdReport} className="col-12 col-md-6 col-lg-4">
              <Report
                report={report}
                number={index + 1}
                solveReport={() => solveReport(report.IdReport)}
              />
            </div>
          ))}
        </div>

        {loading ? <Spinner /> : null}
        {reports.length === 0 && !loading && (
          <div className="text-center text-muted mt-3">{t("NoReports")}</div>
        )}
        {hasMore && !loading && reports.length !== 0 && (
          <button
            className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
            onClick={loadMore}>
            {t("LoadMore")}
          </button>
        )}
        {!hasMore && reports.length !== 0 && (
          <div className="text-center text-muted mt-3">
            {t("NoMoreReports")}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);
