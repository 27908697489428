import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => ({ state: state.modal });

const Modal = (props: any) => {
  // const state = props.state;
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <>
      <div
        className={`asm-backdrop ${open ? "active" : ""}`}
        onClick={() => props.CloseModal()}></div>

      <div className={`asm-modal shadow p-3 ${open ? "active" : ""} rounded`}>
        <div className="mt-2 d-flex justify-content-between border-bottom">
          <h5>{props.title}</h5>
          <i
            className="bi bi-x-lg cursor-pointer ms-2"
            onClick={() => props.CloseModal()}></i>
        </div>
        <div className=" mt-3">{props.children}</div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Modal);
